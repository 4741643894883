<template>
    <div id="container">
        <div id="contents">
			<div class="contents_body">
				<div class="Search">
					<div class="inner">
						<!--기본검색 항목-->
						<div class="select_wrap">
							<select v-model="input.searchDiv" @change="input.jobName = input.jobInstanceId = ''"> 
								<option value="jobName">배치작업명</option> 
								<option value="jobInstanceId">Instance ID</option> 
							</select>
						</div>
						<input type="text" class="s215 ng-hide" v-if="input.searchDiv == 'jobName'" v-model="input.jobName" @keyup.enter="search" placeholder="배치작업명"/>
						<input type="text" class="s215 ng-hide" v-if="input.searchDiv == 'jobInstanceId'" v-model="input.jobInstanceId" @keyup.enter="search" placeholder="Instance ID"/>
						
						<span class="btn gray" @click="search">조회</span>
					</div>
	  			</div>
				<div class="Board">
					<table class="Board_type1">
						<colgroup>
							<col width="50"/><!-- No -->
							<col width="60"/><!-- Instance ID -->
							<col width="180"/><!-- Job 이름 -->
							<col width="60"/><!-- 실행 ID -->
							<col width="90"/><!-- 시작시간 -->
							<col width="90"/><!-- 종료시간 -->
							<col width="100"/><!-- 상태 -->
							<col width="180"/><!-- 종료메시지 -->
							<col width="*"/><!-- 파라미터 -->
							<col width="*"/><!-- CONTEXT -->
						</colgroup>
						<thead>
							<tr>
								<th>No</th>
								<th>Instance ID</th>
								<th>배치작업명</th>
								<th>실행 ID</th>
								<th>시작시간</th>
								<th>종료시간</th>
								<th>상태</th>
								<th>종료메시지</th>
								<th>파라미터</th>
								<th>결과 CONTEXT</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in batchList" :key="item.jobExecutionId" :class="{'red': item.status != 'COMPLETED'}">
								<td>{{ (pageInfo.pageIndex - 1) * pageInfo.pageSize + (index + 1) }}</td>
								<td>{{ item.jobInstanceId }}</td>
								<td class="over" @click="goADMBAT001M02(item)">{{ item.jobName }}</td>
								<td class="over" @click="goADMBAT001M02(item)">{{ item.jobExecutionId }}</td>
								<td>{{ item.startTime }}</td>
								<td>{{ item.endTime }}</td>
								<td>{{ item.status }}<span v-if="item.status != item.exitCode"> ({{item.exitCode}})</span></td>
								<td :title="item.exitMessage">{{ item.exitMessage }}</td>
								<td class="head">
                                    <div v-for="param in item.paramList" :key="param.key">
                                        <span>{{ param.key }}</span> : <span>{{ param.value }}</span>
                                    </div>
                                </td>
								<td class="head">
                                    <div v-for="context in item.contextList" :key="context.key">
                                        <span>{{ context.key }}</span> : <span>{{ context.value }}</span>
                                    </div>
                                </td>
							</tr>
						</tbody>
					</table>
					<div v-if="batchList.length == 0">
						<td>결과가 없습니다.</td>
					</div>
				</div>

                <!--페이징 컴포넌트 -->
                <pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
                <!--// 페이징 컴포넌트 -->

			</div><!--//contents_body-->
        </div>
        <!--//contents-->
    </div>
    <!--//container-->
</template>

<script>
    import pagingComp from "@/components/PagingComp.vue";
    export default {
        components: {
            pagingComp
        },
        data() {
            return {
                input: {
                    searchDiv : 'jobName',
                    jobName : '',
                    jobInstanceId : '',
                }, // 검색조건

                batchList : [],

                pageInfo: {},
                pageIndex: 1
            };
        },
        beforeCreate() { 
            // console.log('beforeCreate');
        },
        created() { 
            // console.log('created');
        },
        beforeMount() { 
            // console.log('beforeMount');
        },
        mounted() { 
            // console.log('mounted');
            this.getBatchList();
        },
        beforeUpdate() { 
            // console.log('beforeUpdate');
        },
        updated() { 
            // console.log('BBS001M01 update');
        },
        beforeDestroy() {
            // console.log("beforeDestroy");
        },
        destroyed() {
            // console.log("destroyed");
        },
        methods: {

            search() {
                this.pageIndex = 1;
                this.getBatchList();
            },

            getBatchList() {

                this.input.pageIndex = this.pageIndex;

                this.$.httpPost("/api/bat/adm/getBatchList", this.input)
                    .then((res) => {
                        // console.log(res.data);
                        
                        this.batchList = res.data.list;
                        this.pageInfo = res.data.pageInfo;

                        // paramJson 파싱
                        this.parsingParamJson();

                    })
                    .catch((err) => {
                        // console.log("=============================");
                        // console.log(err);
                        // console.log(err.response);
                        // console.log("=============================");
                        alert(err
                            .response
                            .data
                            .error_description);
                    });
            },

            // paramJson 파싱
            parsingParamJson() {
    
                for(var item of this.batchList) {

                    if(item.paramJson == undefined || item.paramJson == '') {
                        continue;
                    }

                    var json = JSON.parse(item.paramJson);
    
                    var list = [];
                    for(var key in json){
                        list.push({
                            key   : key,
                            value : json[key]
                        });
                    }

                    item.paramList = list;
                }
            },

            goADMBAT001M02(item) {
                //this.$router.push("/adm/ADMNOTICE01M02?bbsId=10001&nttId=" + nttId);

                var page = {
                    name : 'ADMBAT01M02',
                    params : {
                        jobExecutionId : item.jobExecutionId
                    }
                };

                this.$router.push(page);
            },

            goPage(page) { // console.log(page);
                this.pageIndex = page;
                this.getBatchList();
            },

        }
    };
</script>